import axios from 'axios'
import React,{ useState,useEffect,useContext } from 'react'

const DataContext = React.createContext()

//custom hook useContext
export function useData(){
    return useContext(DataContext)
}
export function DataProvider({children}){

    const [data,setData]=useState([])  
    const [isLoading, setIsLoading]=useState(true)

    async function fetchData(){
        setIsLoading(true)
        await axios.get("/products")
          .then(res=>{
            console.log('below are res')
            console.log(res)
            //{data: Array(37), 
            //status: 200, statusText: 'OK', 
            //headers: AxiosHeaders, config: {…}, …}
    
            console.log('below are res.data')
            // [{imgSrc: "1705815381762-muzhi.jpg"
            //   price: 100000000000000000
            //   title: "爱木子"
            //   __v:0
            //   _id: "65acad5557f555d62089dfd1"},...]
            setData(res.data)
    
            
            
              setIsLoading(false)
          })
          .catch(err=>console.log(err))
      }
      useEffect(()=>{   
        fetchData()} ,[])

    function getProductData(id){
    //Below is product
    //{imgSrc: "1705815381762-muzhi.jpg"
    //price: 100000000000000000
    //title : "爱木子"
    // __v: 0
    // _id: "65acad5557f555d62089dfd1"}
    let productData = data.find(product=>product._id===id)
    if(productData===undefined){
        console.log("Product data not found for ID:"+id)
        return undefined
    }
    //console.log('below is productData')
    //console.log(productData)
    return productData
  }

    
    return(
        <DataContext.Provider value={{data,setData,fetchData,isLoading,getProductData}}>
            {children}
        </DataContext.Provider>
    )


}