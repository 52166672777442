import React,{useState} from 'react'
import {Button}from 'react-bootstrap'
import {useCart} from '../contexts/CartContext'
import { useCheckoutClickContext } from '../contexts/CheckoutClickContext'
import {NavLink,Link} from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext';
import Checkout from '../pages/Checkout'
import "./Navbar1.css"
function Navbar1(){
    const [menuOpen, setMenuOpen]=useState(false)
    const cart = useCart()
    //const auth=useAuth()
    const {auth,authAdmin}=useAuth()
    const {clicked,setClicked}=useCheckoutClickContext()
    //<AuthContext.Provider value={{contacts,user_id,auth,authAdmin,email,updateStatus}}>
    //please sign up or login by checking authcontext

    // function handleCartClick(){
    //     //productCounts>0?return <Navigate to='/checkout'/>:window.alert('no items in cart')
    //     if(cart.productCounts()>0){//
    //         setClicked(true) 
    //         //window.location.href('/')
            
    //     }
    //     else{window.alert('no items in cart')}
    //     }

//START OF CONDITIONAL LINKS
{/* <Navbar expand="sm">
          
          <Navbar.Brand href="/">Ecommerce-Store</Navbar.Brand>
          {auth?
          <>
            {(authAdmin)?
            <>
            <Navbar.Brand href="/auth/logout">Logout</Navbar.Brand>
            <Navbar.Brand href="/loggedinStore">LoginPage</Navbar.Brand>
            <Navbar.Brand href="/admin">Admin</Navbar.Brand>
            
            </>:
            <>
            
            <Navbar.Brand href="/auth/logout">Logout</Navbar.Brand>
            <Navbar.Brand href="/loggedinStore">LoginPage</Navbar.Brand>
            
            
            </>}
          </>:
          <>
          
          <Navbar.Brand href="/auth/signup">SignUp</Navbar.Brand>
          <Navbar.Brand href="/auth/login">Login</Navbar.Brand>
          
          
          
          </> */}
          


//END OF CONDITIONAL LINKS
  //if(clicked){return <Checkout/>}
//   return 
//   if(clicked){
//     return <Link to='/'/>
//   }
  //else{
    return(
    
    <nav>
        <Link to="/" className='title' >Home</Link>
        <div className="menu" onClick={()=>{
            setMenuOpen(!menuOpen)
        }}>
            <span></span>
            <span></span>
            <span></span>
        </div>
        {auth?
          <>
            {(authAdmin)?
            <>

            <ul className={menuOpen?"open":""}>
                <li>
                    <NavLink to="auth/logout" >Logout</NavLink>
                </li>
                <li>
                    <NavLink to="/loggedinStore" >LoginPage</NavLink>
                </li>
                <li>
                    <NavLink to="/admin" >Admin</NavLink>
                </li>
                <li>
                    <NavLink to='/checkout'>Checkout ({cart.productCounts()} Items)</NavLink>
                </li>
            </ul>   
            </>:
            <>  
            <ul className={menuOpen?"open":""}>
                <li>
                    <NavLink to="auth/logout" >Logout</NavLink>
                </li>
                <li>
                    <NavLink to="/loggedinStore" >LoginPage</NavLink>
                </li> 
                <li>
                    <NavLink to='/checkout'>Checkout ({cart.productCounts()} Items)</NavLink>
                </li>
            </ul>  
            </>}
          </>:
          <>
          <ul className={menuOpen?"open":""}>
            <li>
                <NavLink to="/auth/signup" >SignUp</NavLink>
            </li>
            <li>
                <NavLink to="/auth/login">Login</NavLink>
            </li>
            <li>
                <NavLink to='/checkout'>Checkout ({cart.productCounts()} Items)</NavLink>
            </li>
          </ul>
          </>
          }
        {/*
        <ul>
            <li>
                <Link to='/'>Home</Link>
            </li>
            <li>
                <Link to="/auth/signup" >SignUp</Link>
                
            </li>
            <li>
                <Link to="/auth/login">Login</Link>
                
            </li>
            <li>
                <Link to="auth/logout" >Logout</Link>
                
            </li>
            <li>
                <Link to="/loggedinStore" >LoginPage</Link>
                
            </li>
            <li>
                <Link to="/admin" >Admin</Link>
                
            </li>
            <li>
                <Link to='/checkout'>Checkout ({cart.productCounts()} Items)</Link>
            </li>
        </ul>
        */}
        {/*<Button >Cart ({cart.productCounts()} Items)</Button>*/}
    </nav>
    
  )
//}
}

export default Navbar1
