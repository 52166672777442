import React, { useEffect,useState,useContext } from 'react'
import './SignUp.css'
import user_icon from './Assets/person.png'
import email_icon from './Assets/email.png'
import password_icon from './Assets/password.png'
import axios from 'axios'
import Contacts from '../components/Contacts'
import { useContactsContext } from '../contexts/ContactsContext';
//import {dataContext} from '../App'
import {useAuth} from '../contexts/AuthContext'
function SignUp(){
  //const {auth,setAuth} = useContext(dataContext)
  //const {user_id,contacts,setContacts} = useContactsContext()
//   contacts:{
//     firstname: {type:String},
//     lastname: {type:String},
//     phone: {type:String},
//     address: {type:String,
//              required:[true,'please enter address']},
//     city: {type:String},
//     state: {type:String},
//     zipcode: {type:String},
// }
  const auth = useAuth()

  const [action, setAction]= useState('Sign Up')
  const [username, setUsername]= useState('')
  const [email, setEmail]= useState('')
  const [password, setPassword]= useState('')
  const [stringifyContacts,setStringifyContacts]=useState('')
  const [contacts,setContacts]=useState({
    firstname: '',
    lastname: '',
    phone: '',
    
    address: '',
    city: '',
    state: '',
    zipcode: '',
  })

  useEffect(()=>{
    setStringifyContacts(JSON.stringify(contacts))
  },[contacts])
  //const [contacts, setContacts]=useState({
  //const [zipcode, setZipCode]=useState('')
  
  //const {contacts,setContacts,stringifyContacts,setStringifyContacts}=useContactsContext()
  // useEffect(()=>{
  //   // setContacts({...contacts, zipcode:zipcode})
  //   // setZipCode(contacts.zipcode)
  //   setContacts(stringifyContacts)
  // },[stringifyContacts])

  // const [contacts,setContacts]=useState({
  //   firstname: '',
  //   lastname: '',
  //   phone: '',
    
  //   address: '',
  //   city: '',
  //   state: '',
  //   zipcode: '',
  // })
  //const [stringifyContacts,setStringifyContacts]=useState('')
  //})
  //const {fetchData} = useContext(dataContext)
  const emailError = document.querySelector('.emailError')
  const passwordError = document.querySelector('.passwordError')
  const addressError = document.querySelector('.addressError')
  //const userError = document.querySelector('.userError')
  

 
  const handleInput = (e) => {
    //e.persist();
    setContacts({...contacts, [e.target.name]: e.target.value });
    //setStringifyContacts(JSON.stringify(contacts))
    //console.log('this is handleInput stringify contacts    '+stringifyContacts)

  }
  
  
  const handleSubmit=(e)=>{
    
    
   
    e.preventDefault()
    
    //reset errors
    emailError.textContent=''
    passwordError.textContent=''
    addressError.textContent=''
   
    // setContacts({...contacts, [e.target.name]: e.target.value });
    //setStringifyContacts(JSON.stringify(contacts))
    console.log('this is stringified contacts  '+stringifyContacts)
    let formData=new FormData()
   
    formData.append('email',email)
    formData.append('password',password)
    
    formData.append('contacts', stringifyContacts)
    
    //UNCOMMENT
    axios.post('/auth/signup',formData)
    .then((res)=>{
      auth.updateStatus()
      console.log(res.data)
      //TO UNCOMMENT
      window.location.href = '/';

    }).catch((error)=>{
      //response.data.errors object
      //{ email: '', password: '', 'contacts.address': 'please enter address' }
      emailError.textContent=error.response.data.errors.email
      passwordError.textContent=error.response.data.errors.password
      addressError.textContent=error.response.data.errors.address
     
    })
    
    
  }

  return (
    <>
    <form onSubmit={handleSubmit}>
    <div className="container">
      <div className="Header">
        <div className="text">{action}</div>
        <div className="underline"></div>
      </div>
      <div className="inputs">
        
        {/*<div className="input">
          <img src={user_icon} alt="" />
          <input type="text" 
            name='username'
            value={username}
            onChange={(e)=>setUsername(e.target.value)}
            placeholder="Name" 
            required/>
        </div>
        <div className="userError"></div>*/}
        
        <div className="input">
          <img src={email_icon} alt="" />
          <input type="email" 
            name='email'
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            placeholder="Email Id"
            required/>
        </div>
        <div className="emailError"></div>

        <div className="input">
          <img src={password_icon} alt="" />
          <input type="password" 
            name='password'
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            placeholder="Password"
            required/>
        </div>
        <div className="passwordError"></div>
        {/*<Contacts/>*/}

        {/*BEGIN OF CONTACTS FIELDS */}
{/*         
        <div>
    
    <div className="row">

    <div className="col-md-7">
        <div className="card">
            <div className="card-header">
                <h4>Basic Information</h4>
            </div>
            <div className="card-body">

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <label> First Name</label>
                            <input type="text" name="firstname" onChange={(e)=>setContacts({...contacts,firstname:e.target.value})} value={contacts.firstname} className="form-control" />
                            
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <label> Last Name</label>
                            <input type="text" name="lastname" onChange={(e)=>setContacts({...contacts,lastname:e.target.value})} value={contacts.lastname} className="form-control" />
                            
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <label> Phone Number</label>
                            <input type="number" name="phone" onChange={(e)=>setContacts({...contacts,phone:e.target.value})} value={contacts.phone} className="form-control" />
                            
                        </div>
                    </div>
                   
                    <div className="col-md-12">
                        <div className="form-group mb-3">
                            <label> Full Address</label>
                            <textarea rows="3"  name="address" onChange={(e)=>setContacts({...contacts,address:e.target.value})} value={contacts.address} className="form-control"></textarea>
                           

                        </div>
                    </div>
                    <div className="addressError"></div>
                    <div className="col-md-4">
                        <div className="form-group mb-3">
                            <label>City</label>
                            <input type="text" name="city" onChange={(e)=>setContacts({...contacts,city:e.target.value})} value={contacts.city} className="form-control" />
                            
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group mb-3">
                            <label>State</label>
                            <input type="text" name="state" onChange={(e)=>setContacts({...contacts,state:e.target.value})} value={contacts.state} className="form-control" />
                           
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group mb-3">
                            <label>Zip Code</label>
                            <input type="text" name="zipcode" onChange={(e)=>setContacts({...contacts,zipcode:e.target.value})} value={contacts.zipcode} className="form-control" />
                            
                        </div>
                    </div>
                    
                    
                </div>

            </div>
        </div>
    </div>

    

    </div>
    
    
</div>  */}
    
    



        {/*END OF CONTACTS FIELDS */}

      </div>
      {action==="Sign Up"?<div></div>:<div className="forgot-password">Lost Password? <span>Click Here!</span></div>}
      
      {/*<div className="submit-container">
        <div className={action==="Login"?"submit gray":"submit"} 
        // onClick={(e)=>{
        //     setAction('Sign Up')
        //     handleSubmit(e)
        //   }}
          >Sign Up</div>
        <div className={action==="Sign Up"?"submit gray":"submit"} 
        // onClick={(e)=>{
        //     setAction('Login')
        //     handleSubmit(e)
        //   }}
          >Login</div>
      </div>*/}
      <div className="buttonDiv">
        <button className="button button4" type='submit'>Sign Up</button>  
      </div>
      
    </div>


   


<div class="container">
  
</div>
</form>
</>
  )
}

export default SignUp
