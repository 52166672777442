import React from 'react'
//import {Row, Col} from 'react-bootstrap'

//import ProductCard from '../components/productCard'
//import FormComponent from '../components/FormComponent'
//import LoginSignUp from '../components/LoginSignUp/LoginSignUp'

//import {dataContext} from '../App'
//import {useData} from '../contexts/DataContext'
//import {useData} from '../contexts/DataContext'
//import Checkout  from './Checkout'
import Checkout  from './Checkout'
//import Store from './Store'
import Admin from './Admin'
import {useCheckoutClickContext} from '../contexts/CheckoutClickContext'
function PreAdmin(){
  //const {data} = useContext(dataContext)
  //const {data} = useData()
  const {clicked}=useCheckoutClickContext()
  
  return (
    clicked?<Checkout />:<Admin />
    
  )
    
}
  
export default PreAdmin