import React from 'react'
import {Row, Col} from 'react-bootstrap'

import ProductCard from '../components/productCard'
//import FormComponent from '../components/FormComponent'
//import LoginSignUp from '../components/LoginSignUp/LoginSignUp'

//import {dataContext} from '../App'
//import {useData} from '../contexts/DataContext'
//import {useData} from '../contexts/DataContext'
//import Checkout  from './Checkout'
import Checkout  from './Checkout'
import Store from './Store'
import {useCheckoutClickContext} from '../contexts/CheckoutClickContext'
function Home(){
  //const {data} = useContext(dataContext)
  //const {data} = useData()
  const {clicked,setClicked}=useCheckoutClickContext()
  if(clicked){
    //setClicked(false) 
    return (<Checkout/>)
  }else{return <Store />}
  // return (
  //   clicked?<Checkout />:<Store />
    
  // )
    
}
  
export default Home