import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'

import ProductCard from '../components/productCard'
//import FormComponent from '../components/FormComponent'
//import LoginSignUp from '../components/LoginSignUp/LoginSignUp'

//import {dataContext} from '../App'
//import {useData} from '../contexts/DataContext'
import {useData} from '../contexts/DataContext'
//import Checkout  from './Checkout'
import Checkout  from './Checkout'
function Store(){
  //const {data} = useContext(dataContext)
  const {data} = useData()
  //below is data
  //data [{}....{}] where {} is
  // [{imgSrc: "1705815381762-muzhi.jpg"
  //   price: 100000000000000000
  //   title: "爱木子"
  //   __v:0
  //   _id: "65acad5557f555d62089dfd1"},...]
  return (
    
    <>
      <p>John Koh's Website under construction. Ready soon...</p>
      <h1 align='center' className='p-3'>Welcome to Ladies' Store!</h1>
    
        
      
      <Row xs={1} md={3} className="g-4">
        { 
          data.map((product,i)=>(
            <Col align="center" key={i}>
                <ProductCard product={product}/>
              </Col>     
          ))
        }
        {console.log(data)}
        {/* {
          productsArray.map((product,idx)=>(
              <Col align="center" key={idx}>
                <ProductCard product={product}/>
              </Col>       
            )
          )
        } */}
      </Row>
      
    </>
  )
    
}
  
  
export default Store
