import { useContext } from "react"
import { Button } from "react-bootstrap"
//import { CartContext } from "../CartContext"
import {useCart} from '../contexts/CartContext'
//import { getProductData } from "../productsStore"
//import {dataContext} from '../App'
import {useData} from '../contexts/DataContext'
function CartProduct(props) {
    //const {getProductData}=useContext(dataContext)
    const {getProductData}=useData()
    //const cart = useContext(CartContext)
    const cart = useCart()
    const id=props.id
    const quantity=props.quantity
    const productData = getProductData(id)
    const imgSrc = "/products/image/"+productData?.imgSrc
    //console.log(getProductData('65829c8cc68bb9bcbd084f49'))
    console.log(productData)
    return (
        <>
            <h3>{productData?.title}</h3>
            <img src={imgSrc} width="100" alt=""/>
            <p>quantity:{quantity}</p>
            <p>${(quantity*productData?.price).toFixed(2)}</p>
            
            <Button size="sm" onClick={()=>{cart.deleteFromCart(id)}}>Remove</Button>
            <hr></hr>
        </>
    )
}

export default CartProduct