import React,{useState,useContext} from 'react'
//import {useData} from '../contexts/DataContext'
//import {useData} from './contexts/DataContext'
import{useData} from '../contexts/DataContext'
//import { dataContext } from './App'
// import productsStore from './productsStore'
// const getProductData=productsStore().getProductData
//import { getProductData } from './productsStore'
//import { getProductData } from './productsStore'

// export const CartContext = createContext({
//     items:[],
//     getProductQuantity:()=>{},
//     addOneToCart:()=>{},
//     removeOneFromCart:()=>{},
//     deleteFromCart:()=>{},
//     getTotalCost:()=>{},
// })
const CartContext = React.createContext()

export function useCart(){
    return useContext(CartContext)
}
export function CartProvider({children}){
    //const {getProductData}= useContext(dataContext)
    const {getProductData} = useData()
    //console.log(getProductData('65850b69c9e1077f3a0b47cc')._id)
    let [cartProducts, setCartProducts]=useState([])
    const contextValue={
        //cartProducts [{id:1, quantity:2},{id:2, quantity: 3}]
        resetCart,
        cartProducts,
        productCounts,
        //items:cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost,
    }
    function resetCart(){
        setCartProducts([])
    }
    
    function productCounts(){
        const Counts=cartProducts.reduce((sum,product)=>sum+product.quantity,0)
        return Counts
    }
   
    function getProductQuantity(id){
        let quantity = cartProducts.find(product=>product.id===id)?.quantity
        if(quantity===undefined){
            return quantity=0
        }
        return quantity
    }
    //cartProducts [...{id:1, quantity: 2},{id:2, quantity: 3}]
    function addOneToCart(id){
        const quantity=getProductQuantity(id)
        if(quantity===0){//product is not in cart
            setCartProducts([
                ...cartProducts,
                {
                    id:id,
                    quantity:1
                }
            ])
        }else{
             //cartProducts [...{id:1, quantity: 2},{id:2, quantity: 3}]
            setCartProducts(
                cartProducts.map(
                    product=>product.id===id?{
                        ...product,quantity:product.quantity+1
                    }:product
                )
            )
        }
    }
    //[] filter in products that meets criteria
    //cartProducts = [{id:  , quantity:  }]
    function deleteFromCart(id){
        setCartProducts(
            cartProducts=cartProducts.filter(
                product=> product.id!==id
            )
        )
    }
    function removeOneFromCart(id){
        const quantity = getProductQuantity(id)
        if(quantity===1){
            deleteFromCart(id)
        }else{
            setCartProducts(
                cartProducts.map(
                    product=>product.id===id?
                        {...product, quantity:product.quantity-1}
                        :product
                    
                )
            )
        }
    }
    function getTotalCost(){
        let totalCost=0
        cartProducts.map((product)=>{
                const productData = getProductData(product.id)
               
                totalCost += (productData.price * product.quantity)
                //totalCost += (product.quantity)
                return product
            })
        return totalCost
    }

        
    return (

        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider
