import axios from 'axios'
import React,{ useState,useEffect,useContext } from 'react'

const CheckoutClickContext = React.createContext()

//custom hook useContext
export function useCheckoutClickContext(){
    return useContext(CheckoutClickContext)
}
export function CheckoutClickContextProvider({children}){

    const [clicked,setClicked]=useState(false)
    
    return(
        <CheckoutClickContext.Provider value={{clicked,setClicked}}>
            {children}
        </CheckoutClickContext.Provider>
    )


}