import React, {useState,useEffect} from 'react'
import axios from 'axios'
import {useData} from '../contexts/DataContext'
import {useNavigate, Link} from 'react-router-dom'
import Admin from '../pages/Admin'

//import {Row, Col} from 'react-bootstrap'
//import ProductCardAdmin from '../components/productCardAdmin'
function FormComponent(){
  
  const navigate=useNavigate()
  //const [returnedProducts,setReturnedProducts]=useState(false)
  const [title,setTitle]=useState("title")
  const [price,setPrice]=useState("price")
  const [file,setFile]=useState(null)
  const {fetchData,setData}=useData()

  //const [submitted, setSubmitted]=useState(false)
  // useEffect(()=>{
  //   setSubmitted(false)
  // },[])



  const handleSubmit = (e)=>{
    e.preventDefault()
    
    let formData=new FormData()
    formData.append('file',file)
    formData.append('title',title)
    formData.append('price',price)
      
    axios.post('/products',formData)
    .then((res)=>{
      console.log(res)
      //setSubmitted(true)
      
      fetchData()
      navigate('/admin')
      //window.location.href='/admin'
      //return ( <Navigate to='/admin'/>)
      //return (<Link to="/admin" />)
      //window.location.reload(true)
      //return (<Admin/>)
      
     })
     //navigate('/admin')
     //return ( <Link to='/admin'/>)
  }
  //fetchData and useEffect


   


  return (
    // submitted?
    // <Navigate to="/admin" />:
    <> 
      {console.log(file)}
      <form onSubmit={handleSubmit}>
        <label>Title:</label>
        <input 
          type='text' 
          id='title' 
          name='title'
          value={title}
          onChange={(e)=>setTitle(e.target.value)}
        />
        <br/>
        <label>Price:</label>
        <input 
          type='text' 
          id='price' 
          name='price'
          value={price}
          onChange={(e)=>setPrice(e.target.value)}
        />
        <br/>
        <input type='file' 
          name='file' 
          onChange={(e)=>setFile(e.target.files[0])} />
        <br/>
        <button type='submit'>Submit</button>  
      </form>
      
    </>  
    
  )
  
}

export default FormComponent

