import axios from 'axios'
import React,{ useState,useEffect,useContext } from 'react'

const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider({children}){
    const [authAdmin,setAuthAdmin] = useState(null)
    const [auth,setAuth]=useState(null)
    const [email,setEmail]=useState(null)
    const [user_id,setUser_id]=useState("")
    const [contacts,setContacts]=useState({})
   
    //{auth:true,email:user.email,user_id:user.id,contacts:user.contacts}
    async function updateStatus(){
            //authAdmin requires token
            await axios.get('/auth/authAdmin')
                .then(res=>{
                    setAuthAdmin(res.data.auth)
                    setEmail(res.data.email)
                    setUser_id(res.data.user_id)
                    //res.data.contacts
                    //{"firstname":"anotherFirstname",
                    // "lastname":"anotherLastname",
                    // "phone":"2323",
                    // "address":"another full address",
                    // "city":"another city","state":"another state",
                    // "zipcode":"another my zip code"}
                    setContacts(res.data.contacts)
                })
                .catch(err=>console.log(err))
            //authRoute requires token
            await axios.get('/auth/authRoute')
                .then(res=>{      
                    setAuth(res.data.auth)
                    setEmail(res.data.email)
                    setUser_id(res.data.user_id)
                    setContacts(res.data.contacts)          
                })
                .catch(err=>console.log(err))
    }
    useEffect(()=>{updateStatus()},[])

  
    return(
         <AuthContext.Provider value={{contacts,user_id,auth,authAdmin,email,
         updateStatus,setAuth,setAuthAdmin,setEmail,setContacts,setUser_id}}>
            {children}
        </AuthContext.Provider>
    )
}