
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import NavbarComponent from './components/Navbar'
import Logout from './components/Logout'
import {Container} from 'react-bootstrap'
import {BrowserRouter,Routes,Route}from 'react-router-dom'
import Cancel from './pages/Cancel'
import Success from './pages/Success'
import SignUp from './pages/SignUp'
import Login from './pages/Login'
//import Store from './pages/Store'
import Home from './pages/Home'
import LoggedinStore from './pages/LoggedinStore'
import Auth from './components/Auth'
//import CartProvider from './CartContext';
import CartProvider from './contexts/CartContext';
//import { createContext } from 'react';
//import axios from 'axios'
// import {GreetingsProvider} from './contexts/AuthContext'
import {AuthProvider} from './contexts/AuthContext'
import Admin from './pages/Admin';
import AuthAdmin from './components/AuthAdmin'
//import Greetings from './components/Greetings';
//import ConditionalComponent from './components/ConditionalComponent';
//import ConditionalcomponentContextProvider from './contexts/ConditionalcomponentContext';
//import ConditionalComponent from './components/ConditionalComponent';
//import Cookies from 'universal-cookie'
//import jwt from 'jwt-decode'

import { DataProvider } from './contexts/DataContext';
//import Checkout from './pages/Checkout';
import {useCart} from './contexts/CartContext'
import { CheckoutClickContextProvider } from './contexts/CheckoutClickContext';
import PreAdmin from './pages/PreAdmin';
import PreLoggedinStore from './pages/PreLoggedinStore';
import { ContactsContextProvider } from './contexts/ContactsContext';
import Navbar1 from './components/Navbar1';
import Greetings from './components/Greetings'
import Checkout from './pages/Checkout';
import Login1 from './pages/Login1';
//import { CheckoutProvider } from './contexts/CheckoutContext';
//export const dataContext=createContext()
function App() {
  // const cookies = new Cookies()
  
  const cart = useCart()
  // const [auth,setAuth]=useState(null)
  // const [email,setEmail]=useState(null)
  
  // const [data,setData]=useState([])  
  // const [isLoading, setIsLoading]=useState(true)

  // const logout=()=>{
  //   setUser = null
  //   cookies.remove('jwt-authorization')
    
  // }

  // const login=(jwt_token)=>{
  //   const decoded = jwt(jwt_token)
  //   setUser(decoded)
  //   cookies.set('jwt-authorization',jwt_token,{
  //     expires:new Date(decoded.exp*1000)
  //   })
  // }

  // async function fetchData(){
  //   setIsLoading(true)
  //   await axios.get("/products")
  //     .then(res=>{
  //       console.log('below are res')
  //       console.log(res)
  //       //{data: Array(37), 
  //       //status: 200, statusText: 'OK', 
  //       //headers: AxiosHeaders, config: {…}, …}

  //       console.log('below are res.data')
  //       console.log(res.data)
  //       // [{imgSrc
  //       //   : 
  //       //   "1705815381762-muzhi.jpg"
  //       //   price
  //       //   : 
  //       //   100000000000000000
  //       //   title
  //       //   : 
  //       //   "爱木子"
  //       //   __v
  //       //   : 
  //       //   0
  //       //   _id
  //       //   : 
  //       //   "65acad5557f555d62089dfd1"},...]
  //       setData(res.data)
  //       //console.log(res.data)
        
  //       //console.log(res)
  //         setIsLoading(false)
  //     })
  //     .catch(err=>console.log(err))
  // }


    //res.data.auth=null/true/false
    // await axios.get('/auth/authRoute')
    //   .then(res=>{
    //     setAuth(res.data.auth)
    //     setEmail(res.data.email)
    //   })
    //   .catch(err=>console.log(err))
    
  
    // let promise1 = axios.get("/products")
    // let promise2 = axios.get('/auth/authRoute')

  //   await axios.get("/auth/authRoute")
  //   .then(res=>{  
  //       //below is res.data
  //       //{auth: true}
  //       //below is res.data.auth
  //        //true
  //     console.log(res.data.auth)
  //     setAuth(res.data.auth)
  //   })
  //   .catch(err=>{
  //     setAuth(false)
  //     console.log(err)
      
  // }) 

    // Promise.all([promise1, promise2]).then(res=>{
    //   //from Promise1
    //   console.log(res)
    //   setData(res[0].data)
    //   setIsLoading(false)
    //   //from Promise2
    //   console.log(res[1].data)
    //   //res[1].data is {auth:true/false,email}
    //   //req.user={auth:true,email:email}
    //   // setAuth(res[1].data.auth)

    // }).catch(err=>{
    //   console.log(err)}
    // )  
  
    //,err=>{console.log(err)})

    // await axios.get("/products")
    //   .then(res=>{
        
    //     setData(res.data)
    //     //console.log(res.data)
    //     console.log(res)
    //       setIsLoading(false)
    //   })
    //   .catch(err=>console.log(err))
  
  
  //console.log(data)
  // function getProductData(id){
  //   //Below is product
  //   //{imgSrc: "1705815381762-muzhi.jpg"
  //   //price: 100000000000000000
  //   //title : "爱木子"
  //   // __v: 0
  //   // _id: "65acad5557f555d62089dfd1"}
  //   let productData = data.find(product=>product._id===id)
  //   if(productData===undefined){
  //       console.log("Product data not found for ID:"+id)
  //       return undefined
  //   }
  //   console.log('below is productData')
  //   console.log(productData)
  //   return productData
  // }

  // useEffect(()=>{   
  //   fetchData()} ,[])
  //const click=true
  return (
      //<dataContext.Provider value={{data,setData,getProductData,fetchData,isLoading,auth,setAuth,email,setEmail}}>
        
      <DataProvider>
      <AuthProvider>
      <CartProvider>
      <CheckoutClickContextProvider>
        <>
         <header>
            <Navbar1/>
         </header>
         
         <Container>
          
          <Greetings/>
          
            
          
            <Routes>
              
             
              <Route path='/' element= {<Home />}/>
              
              <Route path='/loggedinStore' element={
                <Auth>
                  <PreLoggedinStore/>
                </Auth>
              }/>

              
                 

              <Route path='/admin' element={
                <AuthAdmin>
                    <PreAdmin/>
                </AuthAdmin>
              }/>


              <Route path='/checkout' element={<Checkout/>}/>
              <Route path='/auth/signup' element={<SignUp/>}/>
              <Route path='/auth/login' element={<Login/>}/>
             
              <Route path='/auth/logout' element={<Logout/>}/>
              
             
            
              <Route path="/success" element= {<Success/>}/>
              <Route path="/cancel" element={<Cancel/>}/>            
            </Routes>
         </Container> 
         </>
        </CheckoutClickContextProvider>
        </CartProvider>
        </AuthProvider>
        </DataProvider>
      
  );
  //START OF BLOCK CODE INSIDE RETURN
{/*<DataProvider>
      <AuthProvider>
      <CartProvider>
      <CheckoutClickContextProvider>
      <Container>        
    */}


   {/*</CheckoutClickContextProvider>
      </CartProvider>
      </AuthProvider>
      </DataProvider>
      </Container>
      
          */}
  //END OF BLOCK CODE INSIDE RETURN

  
  // return (
  //   <div>
  //     <h1>Hello John</h1>
  //   </div>
  // )
}
export default App
