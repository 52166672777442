import {Card,Button,Form,Row,Col} from 'react-bootstrap'
//import {CartContext} from '../CartContext'
//import {useContext} from 'react'
import {useCart} from '../contexts/CartContext'
//parent is store
function ProductCard(props){//props for product passed in to it
    // product 
    //{imgSrc: "1705815381762-muzhi.jpg"
    //   price: 100000000000000000
    //   title: "爱木子"
    //   __v:0
    //   _id: "65acad5557f555d62089dfd1"}
    const product = props.product
    //const cart = useContext(CartContext)
    const cart = useCart()
    const productQuantity = cart.getProductQuantity(product._id)
    const imgSrc = "/products/image/"+product.imgSrc
    //console.log(cart.items)
    console.log(imgSrc)
    return(
        <Card>
            <Card.Body>
                <img src={imgSrc} width="250" alt=""/>
                <Card.Title>{product.title}</Card.Title>
                <Card.Text>Price:Usd{product.price}</Card.Text>
                {/* <Card.Text>{product.price}</Card.Text> */}
                {/* <Card.Title>{product.title}</Card.Title>
                
                <Card.Text>${product.price}</Card.Text> */}
                {productQuantity>0?
                    <>
                        <Form as={Row}>
                            <Form.Label column="true" sm="6">In Cart: {productQuantity}</Form.Label>
                            <Col sm="6" >
                                <Button sm="6" className="mx-2" onClick={()=>cart.addOneToCart(product?._id)}>+</Button>
                                <Button sm="6" className="mx-2" onClick={()=>cart.removeOneFromCart(product?._id)}>-</Button>
                            </Col>
                        </Form>
                        <Button sm="6"   className="my-2" onClick={()=>cart.deleteFromCart(product?._id)}>Remove from Cart</Button>
                    </>:
                    <Button variant="primary" onClick={()=>cart.addOneToCart(product._id)}>Add To Cart</Button>
                }

            </Card.Body>
        </Card>

    )
}

export default ProductCard