import React,{useState,useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import axios from 'axios'
//import Admin from '../pages/Admin'
function AuthAdmin({children}) {
    
    const [auth,setAuth]=useState(null)
    async function fetch(){
      //console.log('below are children')
      //console.log(children)
     
      //if(Object.is(children,Admin)){
      //   console.log('below are children')
      //   console.log(children)
      //   await axios.get("/auth/admin")
      //   .then(res=>{  
      // if(to='storelogin'){
      //   console.log('storelogin')
      // }
      // if(to='admin'){
      //   console.log('admin')
      // }
      //   console.log('setAuth(true or false)')
      //   setAuth(res.data.auth)
      // })
      // .catch(err=>{
      //   setAuth(false)
      //   console.log(err)
        
      // }) 

      //}
      //else{

        //console.log('not in admin route')
        //await axios.get("/storelogin")
        // await axios.get("/admin")
        await axios.get("/auth/authAdmin")
        .then(res=>{  
            //below is res.data
            //{auth: true}
            //below is res.data.auth
            //true
          //console.log('in Auth component respong /auth/authRoute')
          //console.log('res.data.auth either true or false')
          //console.log(res.data.auth)
          
          //console.log('setAuth(true or false)')
          setAuth(res.data.auth)
        })
        .catch(err=>{
          setAuth(false)
          console.log(err)
          
        }) 
      //}
    }
    useEffect(()=>{
        fetch()
    },[])
    

    // //if(currentUser===undefined){
    // if(auth===undefined){
    //     return null
    // }
    // //if(!currentUser){
    // if(!auth){
    //     //return <Navigate to='/login'/>
    //     return <Navigate to='/login'/>
    // }
    //  return children

    switch(auth){
      case true:
        return children
      case false:
        return <Navigate to='/auth/login'/>
      case null:
        return <div className="Loading">Loading...</div>
    }
}

export default AuthAdmin