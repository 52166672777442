
//import{useData} from '../contexts/DataContext'
import React,{useState,useEffect} from 'react'
import Contacts from '../components/Contacts'
import axios from 'axios'
import CartProduct from '../components/CartProduct'
import {Button}from 'react-bootstrap'
//import {useCart} from '../contexts/CartContext'
import {useCart} from '../contexts/CartContext'
import {useAuth} from '../contexts/AuthContext'
import { useCheckoutClickContext } from '../contexts/CheckoutClickContext'
function Checkout() {

//START OF DATA
const cart=useCart()
const auth=useAuth()
const [email,setEmail]=useState('')
const [user_id,setUser_id]=useState('')

const {clicked,setClicked}=useCheckoutClickContext()

// const [error, setError] = useState([]);

  return (
    
    <div>  
        <h1>Checkout </h1>
          {/*<div className="py-4">
            <div className="container">
                
            </div>
            </div>*/}
            {cart.productCounts()>0?
            <>
            <p>Items in your cart:</p>

                    {cart.cartProducts.map((product,idx)=>(
                   
                        <CartProduct key={idx} id={product.id} quantity={product.quantity}/>
                    ))}
                    <h1>Total:{cart.getTotalCost().toFixed(2)}</h1>

                {/*<Contacts/>*/}
                <Button variant="success" onClick={()=>{
                      
                      //cart.cartProducts
                      //[{…}, {…}] 
                      //[{id: '65aa5a082285dda2877ed34f', quantity: 3}
                      //,{id: "65aa5a082285dda2877ed34f", quantity: 1}]
                      axios.post('/products/create-checkout-session',cart.cartProducts)
                      .then((res)=>{    
                       window.location = res.data.url  
                      })
                      .catch((error)=>{console.log(error)})

                      }}>Purchase Items!</Button>
                  </>
                :
                  <h1>There are no items in your cart!</h1>}

</div>
  )
}
export default Checkout
