// // import React from 'react'
// // import FormComponent from '../components/FormComponent'
// // import Store from './Store'

// // //import {dataContext} from '../App'

  


// // function Admin(){
// //   //const {data} = useContext(dataContext)
// //   return (
// //     <>
// //     <FormComponent/>
// //     <Store/>
// //     </>
// //   )
// // }

// // export default Admin
// import React from 'react'
// //import {Row, Col} from 'react-bootstrap'

// //import ProductCard from '../components/productCard'
// import FormComponent from '../components/FormComponent'
// //import LoginSignUp from '../components/LoginSignUp/LoginSignUp'
// //import {dataContext} from '../App'
// import Store from './Store'
// function Admin(){
//   //const {data} = useContext(dataContext)
  
//   // if(isLoading){
//   //   return 
//   //     // (<>
//   //     //   <p>Loading...</p>
        
//   //     // </>)
    
//   // }
//   return (
//     <>
//       <FormComponent/>
//       <Store/>
//       {/*<h1 align='center' className='p-3'>Welcome to our Store!</h1>
      
//       <Row xs={1} md={3} className="g-4">
//         { 
//           data.map((product,i)=>(
//             <Col align="center" key={i}>
//                 <ProductCard product={product}/>
//               </Col>     
//           ))
//         }
//         {console.log(data)}
//         {/* {
//           productsArray.map((product,idx)=>(
//               <Col align="center" key={idx}>
//                 <ProductCard product={product}/>
//               </Col>       
//             )
//           )
//         } 
//       </Row>*/}
//     </>
//   )
    
// }
  
  
// export default Admin



////////
//import {Button} from 'react-bootstrap'
import axios from 'axios'
import React,{useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'

import ProductCardAdmin from '../components/productCardAdmin'
import FormComponent from '../components/FormComponent'
//import LoginSignUp from '../components/LoginSignUp/LoginSignUp'
 
//import {dataContext} from '../App'
//import Store from './Store'
//import {useData} from '../contexts/DataContext'
import {useData} from '../contexts/DataContext'
import Store from './Store'
function Admin(){
  //const {data} = useContext(dataContext)
  const {data,setData} = useData()

  async function fetchData(){
    //setIsLoading(true)
    await axios.get("/products")
      .then(res=>{
        //console.log('below are res')
        //console.log(res)
        //{data: Array(37), 
        //status: 200, statusText: 'OK', 
        //headers: AxiosHeaders, config: {…}, …}

        //console.log('below are res.data')
        // [{imgSrc: "1705815381762-muzhi.jpg"
        //   price: 100000000000000000
        //   title: "爱木子"
        //   __v:0
        //   _id: "65acad5557f555d62089dfd1"},...]
        setData(res.data)

        
        
          //setIsLoading(false)
      })
      .catch(err=>console.log(err))
  }

  useEffect(()=>{
    fetchData()
  },[])
  return (
    <>
      <h1>Admin Page</h1>
      <FormComponent/>
      
      {/*<Store/>*/}     
      <h1 align='center' className='p-3'>Welcome to our Store!</h1>
      
      <Row xs={1} md={3} className="g-4">
        { 
          data.map((product,i)=>(
            <Col align="center" key={i}>
                <ProductCardAdmin product={product}/>
              
              </Col>     
          ))
        }
        {console.log(data)}
        {/*{
          productsArray.map((product,idx)=>(
              <Col align="center" key={idx}>
                <ProductCard product={product}/>
              </Col>       
            )
          )
        } */}
      </Row>
    </>
  )
}
  
export default Admin