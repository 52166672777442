import React, { useState} from 'react'
//import './SignUp.css'
import './Login.css'
//import user_icon from './Assets/person.png'
import email_icon from './Assets/email.png'
import password_icon from './Assets/password.png'
import axios from 'axios'
import {Link} from 'react-router-dom'
//import {dataContext} from '../App'
import {useAuth} from '../contexts/AuthContext'
import Admin from './Admin'
import Home from './Home'
import LoggedinStore from './LoggedinStore'

function Login(){
  //const {auth,setAuth} = useContext(dataContext)
  const [isAdmin,setIsAdmin]=useState(false)
  const [isAuth,setIsAuth]=useState(false)
  const auth=useAuth()
  //const [action, setAction]= useState('Login')
  //const [username, setUsername]= useState('')
  const [email, setEmail]= useState('')
  const [password, setPassword]= useState('')
  //const {fetchData} = useContext(dataContext)
  const emailError = document.querySelector('.emailError')
  const passwordError = document.querySelector('.passwordError')
  //const userError = document.querySelector('.userError')
  const handleSubmit=(e)=>{
    
    e.preventDefault()
    emailError.textContent=''
    passwordError.textContent=''
    //userError.textContent=''

    let formData=new FormData()
    //formData.append('username',username)
    formData.append('email',email)
    formData.append('password',password)
    //console.log(formData.username, formData.email, formData.password)
    
    axios.post('/auth/login',formData)
    .then((res)=>{
      //below is res.data
      // {contacts:{}
      // createdAt: "2024-04-21T02:52:40.766Z"
      // email: "jkoh990@gmail.com"
      // isAdmin: true
      // updatedAt: "2024-04-21T02:52:40.766Z"
      // __v: 0
        console.log('below is res.data')
        console.log(res.data)
        // if(res.data.isAdmin){
        //   setIsAdmin(true)
        // }

        // below is the res.data from server
        // const {password,isAdmin, _id, ...others}=user._doc
        // res.status(200).json({...others})
         
        //res.data
        //{email: 'youremail@gmail.com', 
        //createdAt: '2024-03-14T04:55:19.782Z', 
        //updatedAt: '2024-03-14T04:55:19.782Z', 
        //__v: 0, 
        //accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1Z…gxOX0.6Ww0_r6UFWgpCYhlLsPN2DxfpzLK9nUDP84uDQkc_YI'
        //}
        
        //console.log(username, email, password)
        if(res.data){
          //set authcontext
          //<AuthContext.Provider value={{setAuth,setAuthAdmin,contacts,user_id,auth,authAdmin,email,updateStatus}}>
          
          //check login
          auth.updateStatus()
          
          // if(res.data.isAdmin){
          //   auth.setAuthAdmin(true)
          // }else{auth.setAuth(true)}


          //console.log(res.data.email)
          //setAuth(true)
          // console.log('Below is res.data')
          // console.log(res.data)
          //{email: 'jkoh990@gmail.com', 
          //isAdmin:true,
          //createdAt: '2024-04-02T09:54:48.824Z', 
          //updatedAt: '2024-04-02T09:54:48.824Z', __v: 0}
          
          // (res.data.email==='')?

          //TEST SECTION 
          // (res.data.isAdmin)?
          // window.location.href='/admin':
          // window.location.href='/'
          // (res.data.isAdmin)?
          // <Redirect to='/admin'/>:
          // <Redirect to='/'/>
          //TEST SECTION

          //window.location.href = '/';
        }

           
    // if(auth.authAdmin||auth.auth){
    //   if(auth.authAdmin){
    //     <Link to='/admin'/>
    //     //return(<Admin/>)
    //   }
    //   if(auth.auth){
    //     <Link to='/loggedinStore'/>
    //     //return(<LoggedinStore/>)
    //   }
    // }


        // if(res.data.errors){
        //   console.log(res.data.errors)
        //   emailError.textContent=res.data.errors.email
        //   passwordError.textContent=res.data.errors.password
        // }
      //fetchData()
     }).catch((error)=>{
      console.log(error)
      //below is error.response.data.errors
      //{email: 'please enter a valid email', 
      //password: 'minimum password length is 8 characters'}
      // console.log(error.response.data.errors.email)
      // console.log(error.response.data.errors.password)



      //console.log(error.response.data.errors)
      
      // userError.textContent=error.response.data.errors.user
     
      //console.log(error.response.data.errors)

      emailError.textContent=error.response.data.errors.email
      passwordError.textContent=error.response.data.errors.password
    
    })

  }
  //<AuthContext.Provider value={{setAuth,setAuthAdmin,contacts,user_id,auth,authAdmin,email,updateStatus}}>
  //return (
    // console.log('auth.authAdmin is  '+auth.authAdmin)
    // //if(isAdmin){
    // console.log('auth is  '+JSON.stringify(auth))
    // console.log('auth.auth is '+auth.auth)
    //if(auth.auth){


    if(auth.authAdmin||auth.auth){
      if(auth.authAdmin){
        //<Link to='/admin'/>
        return(<Admin/>)
      }
      if(auth.auth){
        //<Link to='/loggedinStore'/>
        return(<LoggedinStore/>)
      }
    }else 
    return(
    
    <form onSubmit={handleSubmit}>
    <div className="Container">
      <div className="Header">
        {/*<div className="text">{action}</div>*/}
        <div className="text">Login</div>
        <div className="underline"></div>
      </div>
      <div className="inputs">
        
        {/*<div className="input">
          <img src={user_icon} alt="" />
          <input type="text" 
            name='username'
            value={username}
            onChange={(e)=>setUsername(e.target.value)}
            placeholder="Name" 
            required/>
        </div>
        <div className="userError"></div>
        */}
        <div className="input">
          <img src={email_icon} alt="" />
          <input type="email" 
            name='email'
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            placeholder="Email"
            required/>
        </div>
        <div className="emailError"></div>

        <div className="input">
          <img src={password_icon} alt="" />
          <input type="password" 
            name='password'
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            placeholder="Password"
            required/>
        </div>
        <div className="passwordError"></div>
        <div className="forgot-password">Lost Password?<span>Click Here!</span></div>
        {/*action==="Sign Up"?<div></div>:<div className="forgot-password">Lost Password?<span>Click Here!</span></div>*/}
      </div>
      
      
      {/*<div className="submit-container">
        <div className={action==="Login"?"submit gray":"submit"} 
        // onClick={(e)=>{
        //     setAction('Sign Up')
        //     handleSubmit(e)
        //   }}
          >Sign Up</div>
        <div className={action==="Sign Up"?"submit gray":"submit"} 
        // onClick={(e)=>{
        //     setAction('Login')
        //     handleSubmit(e)
        //   }}
          >Login</div>
      </div>*/}
      <div className="buttonDiv">
        <button className="button button4" type='submit'>Login</button>  
      </div>
      
    </div>

    {/*<div class="container">
  
    </div>*/}
    </form>
    
  )
}

export default Login