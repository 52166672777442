import React from 'react'
import {Row, Col} from 'react-bootstrap'

import ProductCard from '../components/productCard'
//import FormComponent from '../components/FormComponent'
//import LoginSignUp from '../components/LoginSignUp/LoginSignUp'
import {useData} from '../contexts/DataContext'
//import {dataContext} from '../App'
function LoggedinStore(){
  //const {data} = useContext(dataContext)
  const {data} = useData()
  // if(isLoading){
  //   return 
  //     // (<>
  //     //   <p>Loading...</p>
        
  //     // </>)
    
  // }
  return (
    <>
     
      <h1 align='center' className='p-3'>Welcome to our Store!</h1>
      <h1>For Login Users</h1>
      <Row xs={1} md={3} className="g-4">
        { 
          data.map((product,i)=>(
            <Col align="center" key={i}>
                <ProductCard product={product}/>
              </Col>     
          ))
        }
        {console.log(data)}
        {/* {
          productsArray.map((product,idx)=>(
              <Col align="center" key={idx}>
                <ProductCard product={product}/>
              </Col>       
            )
          )
        } */}
      </Row>
    </>
  )
    
}
  
  


export default LoggedinStore