//import React, { useContext } from 'react'
//import axios from 'axios'
// import {useGreetings} from '../contexts/AuthContext'
import {useAuth} from '../contexts/AuthContext'
//import {dataContext} from '../App'

function Greetings() {
    const {auth,email} = useAuth()
    //const auth = null

    //const {auth,setAuth,email, setEmail} = useContext(dataContext)
    
    // async function fetch(){
    //     await axios.get('/greetings')
    //         .then(res=>{
    //             //res.data.auth = true/false
    //             //res.data.email=jkoh
    //             console.log('in greetings component response /greetings')
    //             console.log('log res.data.auth and res.data.email')
    //             console.log(res.data.auth,res.data.email)
    //             setAuth(res.data.auth)
    //             setEmail(res.data.email)
    //         })
    //         .catch(err=>console.log(err))
    // }
    // useEffect(()=>{fetch()},[])

    //updateStatus()
    
    switch(auth){
        case true:
            return <p>Welcome {email}</p>
        case false:
            return <p>Please log in or sign up (optional)</p>
        case null:
            return <div className="Loading">Loading</div>
    }
    
   
}

export default Greetings

// import React,{useState,useEffect,useContext} from 'react'
// //import axios from 'axios'
// import {ConditionalcomponentContext} from '../contexts/ConditionalcomponentContext'
// function ConditionalComponent(){
//   //const [welcome,setWelcome]=useState('please log in')
  
//   const {auth,setAuth,email,setEmail} = useContext(ConditionalcomponentContext)
//   //setAuth(auth)
//   // async function fetch(){
//   //   await axios.get("/auth/authRoute")
//   //   .then(res=>{  
//   //     //below is res.data
//   //     //{auth: true/false, email:email/null}
//   //     setAuth(res.data.auth)
//   //     setEmail(res.data.email)
//   //   })
//   //   .catch(err=>{
//   //     console.log(err)
        
      
//   // }) 
//   // }
//   // useEffect(()=>{
//   //     fetch()
//   // },[])
 
//   switch(auth){
//     case true:
//       return <p>Welcome {email}</p>
//     case false:
//       return <p>Please log in</p>
//     case null:
//       return <div className="Loading"></div>
//   }



//   return auth? <p>Welcome {email} </p>:<p>Please log in</p>
//   //return <p>Conditional Component</p>
// }

// export default ConditionalComponent