import React,{useState,useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import axios from 'axios'
//import Admin from '../pages/Admin'
//import auth from 'authContext'
import {useAuth} from '../contexts/AuthContext'
function Auth({children}) {
   //auth is an object of {contacts,user_id,auth,authAdmin,email,updateStatus,setAuth,setAuthAdmin}
  //<AuthContext.Provider value={{contacts,user_id,auth,authAdmin,email,updateStatus,setAuth,setAuthAdmin}}>
    //const auth = useAuth()
    const [auth,setAuth]=useState(null)
    //async function fetch(){
      //setAuthState(auth.auth)
      //await axios.get("/storelogin")
      // if(as==='auth'){
        
      //   setAuthState(auth.auth)
      // }
        
      //   //authAdmin
      // if(as==='admin'){
        
      //   setAuthState(auth.authAdmin)
      // }
    //}
    async function fetch(){
      await axios.get("/auth//authRoute")
      .then(res=>{  
        setAuth(res.data.auth)
      })
      .catch(err=>{
        setAuth(false)
        console.log(err)
        
      }) 
    //}
  }
  useEffect(()=>{
      fetch()
  },[])
   

    

    switch(auth){
      case true:
        return children
      case false:
        return <Navigate to='/auth/login'/>
      case null:
        return <div className="Loading">Loading...</div>
    }
}

export default Auth