import {useEffect} from 'react'
//import {Navigate} from 'react-router-dom'
import axios from 'axios'
//import Cookies from 'universal-cookie'
//import {dataContext} from '../App'
import {Navigate} from 'react-router-dom'

//import {dataContext} from '../App'
// import {useGreetings} from '../contexts/AuthContext'
import {useAuth} from '../contexts/AuthContext'
import {useCart} from '../contexts/CartContext'
    
function Logout() {
   //const {auth,setAuth,email, setEmail} = useContext(dataContext)
    //const {updateStatus}=useGreetings()
    //const {updateStatus,auth,authAdmin,email}=useAuth()
    //<AuthContext.Provider value={{contacts,user_id,auth,authAdmin,email,updateStatus,setAuth,setAuthAdmin,
    //setEmail,setContacts,setUser_id}}></AuthContext.Provider>
    const cart=useCart()
    const auth=useAuth()
    async function fetch(){
      //cart.cartProducts=[]
      await axios.get("/auth/logout")
      .then(res=>{  
        console.log('in logout component respond /auth/logout')
        console.log(res)
        //auth.updateStatus()
        auth.setAuth(false)
        auth.setAuthAdmin(false)
        auth.setUser_id('')
        auth.setEmail(null)
        auth.setContacts({})
        
        //CLEAR CART
      //   {
      //     //cartProducts [{id:1, quantity:2},{id:2, quantity: 3}]
      //     cartProducts,
      //     productCounts,
      //     //items:cartProducts,
      //     getProductQuantity,
      //     addOneToCart,
      //     removeOneFromCart,
      //     deleteFromCart,
      //     getTotalCost,
      // }

        //END OF CLEAR CART
        //cart.cartProducts=[]

        // cart.cartProducts.map((item)=>{
        //   if(!item) return console.log('no items in cartProducts')
        //   return console.log('in logout component, item in cart is:  '+item)
        // })
        //console.log('in logout component, res, cart is:  '+cart)
        
        //res.data = {auth:false}
        //setAuth(res.data.auth)
        //setAuth(false)
        //setEmail(null)

        //reset cart
        cart.resetCart()

        console.log('updateStatus completed,redirected to /')
        //window.location.href = '/'
       
      })
      .catch(err=>{
        console.log(err)
    })}

    useEffect(()=>{
        fetch()},[])
    console.log('in logout component before routing to /')
    //updateStatus()
    //window.location.href = '/';
    //return <Navigate to='/'/>
    // cart.cartProducts=[]
    // console.log('in logout component  cart.getTotalCost is  :'+cart.getTotalCost())
    // console.log('in logout component  cart.productCounts is  :'+cart.productCounts())
    
    return(<Navigate to='/'/>)
}

export default Logout
